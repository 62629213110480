@font-face {
    font-family: "Georgian";
    src: url(./fonts/45-Light.woff2) format("woff2");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Georgian";
    src: url(./fonts/55-Roman.woff2) format("woff2");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Georgian";
    src: url(./fonts/75-Bold.woff2) format("woff2");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "nino-mtavruli";
    src: url(./fonts/bpg_nino_mtavruli_normal.otf);
    unicode-range: 10 a0, 10 ff;
}

@font-face {
    font-family: "nino-mtavruli-bold";
    src: url(./fonts/bpg_nino_mtavruli_bold.ttf), format("truetype");
    unicode-range: 10 a0, 10 ff;
}

@font-face {
    font-family: "excelsior-caps";
    src: url(./fonts/bpg_excelsior_caps_dejavu_2010.ttf), format("truetype");
    unicode-range: 10 a0, 10 ff;
}

* {
    margin: 0;
    padding: 0;
}

body {
    font-family: "Georgian", "Helvetica Neue", Helvetica, "Segoe UI", sans-serif;
    font-size: 0.8rem;
}

a {
    text-decoration: none;
}

h1 {
    font-size: 1.6rem;
    font-weight: 300;
}

h2 {
    font-size: 1.5rem;
}

h1,
h2 {
    font-family: "Georgian";
}

.h4 {
    font-size: 1.4rem;
}

p {
    line-height: 1.6;
    font-size: 0.8rem;
}

select {
    padding-left: 10px;
    font-size: 0.8rem;
}

select:disabled,
input:disabled,
textarea:disabled {
    cursor: not-allowed;
}

.faqPriceImg {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 367px;
    height: 292px;
}

textarea,
select,
option {
    outline: none;
}

select option:disabled {
    color: #ccc;
}

button:disabled {
    cursor: not-allowed;
}

button:active {
    outline: none;
}

li {
    list-style-type: none;
}

header {
    width: 100%;
    height: 4.4rem;
}

footer {
    height: 3rem;
}

footer .info {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    text-align: center;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

.terms ol li {
    list-style-type: decimal;
    list-style-position: inside;
    padding-bottom: 1rem;
}

.terms-ul li {
    list-style-type: disc;
    list-style-position: inside;
}

.terms {
    padding: 1rem;
}

.pulse-circle {
    animation: pulse 1.75s infinite ease;
    background-color: transparent;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    display: inline-block;
    margin-left: 8px;
    margin-bottom: -4px;
}

@keyframes pulse {
    0% {
        transform: scale(0.4);
        background: #fff;
        box-shadow: 0 0 0 5px rgb(255, 255, 255, 0.7);
    }
    70% {
        transform: scale(1);
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.7);
    }
    100% {
        transform: scale(0.7);

        box-shadow: 0 0 0 1px rgba(255, 255, 255, 1);
    }
}

header svg,
.grouped-btn svg {
    height: 2.5rem;
}

.hide-visibility {
    visibility: hidden;
}

.btn {
    border-radius: 1.5rem;
    -webkit-border-radius: 1.5rem;
    -moz-border-radius: 1.5rem;
    -ms-border-radius: 1.5rem;
    padding: 0.7rem 3.2rem;
    font-size: 0.75rem;
}

.btn:disabled {
    opacity: 0.2;
}

.btn-toolbar {
    justify-content: center;
}

.btn-blue {
    background-color: #569fec;

    color: #fff;
}

.btn-red {
    background-color: rgb(221, 31, 31);
    margin-right: 10px;
}

.btn-red:hover,
.btn-red:link,
.btn-red:active,
.btn-red:visited {
    background-color: rgb(255, 20, 20);
}

.btn-red:disabled {
    background-color: rgb(255, 104, 104);
}

.btn-save-text {
    display: block;
    margin-left: auto;
    margin-bottom: 0.5rem;
    margin-top: 0.2rem;
    padding: 0.5rem 1.5rem;
}

.btn-delete-file {
    background-color: rgb(221, 31, 31);
    color: #fff;
    margin: 1rem 0;
    padding: 0.5rem 1.5rem;
}

.btn-start-again,
.btn-continue {
    padding: 0.7rem 2rem;
}

.btn-start-again {
    background-color: rgb(221, 31, 31);
    border: none;
}

.btn-start-again:active,
btn-start-again:focus {
    background-color: rgb(255, 20, 20) !important;
}

.header-menu ul {
    display: flex;
    list-style-type: none;
    padding: 1rem;
    color: #787d88;
    /*justify-content: center;*/
}

.table-sm td, .table-sm th {

    width: 30%;
}

.header-row {
    padding-top: 10px;
}

.header-menu ul li {
    padding: 0 1rem;
}

.header-menu ul li a {
    text-decoration: none;
    color: #787d88;
}

.header-menu ul li a:hover {
    color: #569fec;
}

.header-menu .home {
    margin-top: -0.75rem;
    font-size: 1.2rem;
}

.badge-blue {
    background-color: #569fec;
    color: #fff;
    display: inline;
}

.landing-form,
.order-section-row {
    margin-left: 0;
    margin-right: 0;
}

.headline {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.landing-info {
    display: block;
    color: #787d88;
    margin: 5px auto;
    font-size: 1.2rem;
    width: 75%;
}

.landing-section .info-para {
    color: #787d88;
    max-width: 40rem;
    margin-bottom: 2rem;
}

.landing-select {
    border: none;
}

.city-select {
    width: 100%;
    color: #787d88;
    border-radius: 3rem;
    -webkit-border-radius: 3rem;
    -moz-border-radius: 3rem;
    -ms-border-radius: 3rem;
    height: 2.7rem;
}

.landing-select,
.order-select {
    border-radius: 3rem;
    -webkit-border-radius: 3rem;
    -moz-border-radius: 3rem;
    -ms-border-radius: 3rem;
    height: 2.7rem;
}

.landing-select:active,
.landing-select:hover {
    box-shadow: 0 0 10px 100px #fff inset;
}

.landing-about-article {
    margin-top: 4rem;
    height: 44vh;
}

.about-para {
    line-height: 2.1;
}

.landing-about-container {
    margin-left: 0;
    margin-right: 0;
}

.background-layout {
    background-color: #f1f3f5;
    margin-left: 0;
    margin-right: 0;
    height: 100%;
}

._loading_overlay_overlay {
    background-color: rgba(214, 214, 214, 0.8) !important;
}

._loading_overlay_content {
    position: fixed !important;
    margin-top: 17.5rem !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    color: #0576ed !important;
    font-size: 1.3rem !important;
}

._loading_overlay_content svg circle {
    stroke: #0576ed !important;
}

.personal-modal-para {
    font-size: 1rem;
}

.landing-header {
    margin-bottom: 1rem;
    padding-top: 1rem;
    font-weight: bold;
    font-size: 2rem;
}

.upload-btn-wrapper {
    width: 100%;
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.uploaded-file-name {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;
}

.uploaded-file-placeholder {
    /* width: 200px; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}

.upload-btn {
    border: 1px solid #ccc;
    border-radius: 3px;
    background-color: white;
    padding: 0.5rem 3.1rem;
    display: inline;
    border-radius: 8px;
    color: #787878;
    width: 100%;
}

.upload-btn span:hover {
    cursor: pointer;
}

.uploadFile-input:hover {
    cursor: pointer;
}

.uploadFile-input:disabled {
    cursor: not-allowed;
}

.file-placeholder {
    min-width: 8rem;
    /* min-height: 2.4rem; */
}

.notary:hover {
    cursor: default;
}

/*TODO courier*/
.courier:hover {
    cursor: default;
}

.topge {
    text-align: center;
    padding-bottom: 5px;
}

.upload-btn span {
    margin-top: 3.4rem;
    display: block;
    /* max-width: 8rem; */
}

.icon-upload {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -35%);
}

.paste-text-icon {
    position: absolute;
    top: 3.9rem;
    right: 8.5rem;
    overflow: hidden;
}

.upload-btn-wrapper input[type="file"] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    line-height: 12;
    width: 100%;
}

.card-body .or {
    font-size: 1rem;
    position: relative;
    top: -4rem;
    left: 0.5rem;
    color: #000;
}

.order-section .notary-file-label {
    margin: 0.5rem 0;
}

.notray-file-container {
    position: relative;
}

.notary-icon {
    position: absolute;
    top: 1.3rem;
    fill: rgb(120, 125, 136);
    width: 1.5rem;
    height: 1.5rem;
    left: 1rem
}

#med-icon, #notice-icon, #ledger-icon, #agreement-icon, #trust-icon {
    top: 0.8rem;
    fill: currentColor;
}

#agreement-icon, #ledger-icon {
    left: 2.2rem;
    width: 1.36rem;
}

#trust-icon {
    left: 2.25rem;
    width: 1.1rem;
}

.notary-file-input {
    color: transparent;
    width: 100%;
    max-height: 6rem;
    overflow: hidden;

}

.notary-file-input:focus {
    outline: none;
}

.notary-file-input::-webkit-file-upload-button {
    visibility: hidden;
}

.notary-file-input::before {
    content: 'დასკანერებული დოკუმენტის ატვირთვა';
    color: #787878;
    display: inline-block;
    /* background-color: #0576ed; */
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 20px 15px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    width: 100%;
    font-size: 1.2rem;
    text-align: center;

}

.notary-file-input:disabled::before {
    background-color: rgba(87, 159, 236, 0.4);
    /* color: #eee; */
    cursor: not-allowed;
    border: none;
}

.notary-file-input:hover::before {
    border-color: #000;
}

.notary-file-input:active {
    outline: 0;
}

.notary-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}


.spinner-sm {
    display: block;
    margin: 0 45.5%;
}

.fast-service-info {
    margin: 0.5rem 2rem;
}

.file-textarea {
    width: 14rem;
    max-height: 10.91rem;
    min-height: 10.91rem;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    padding: 5px;
    margin-left: 1rem;
    outline: none;
}

.file-textarea::placeholder {
    text-align: center;
    padding-top: 5.2rem;
}

.modal-content {
    min-width: 23rem;
    text-align: center;
}

.modal-input {
    width: 100%;
    display: block;
    border-radius: 0.3rem;
    -webkit-border-radius: 0.3rem;
    -moz-border-radius: 0.3rem;
    -ms-border-radius: 0.3rem;
    padding: 0.2rem 0.5rem;
    outline: none;
    border: 1px solid #ccc;
    min-width: 17rem;
    margin: 3px 0 10px 0;
}

.order-section label {
    margin: 0.3rem 0rem 0.3rem 2rem;
}

.order-section input[type="text"],
.order-section input[type="email"],
.order-section input[type="tel"] {
    margin: 0.3rem 1.8rem;
    width: 80%;
}

.comment-textarea {
    width: 100%;
    height: 5rem;
    border-radius: 0.4rem;
    padding: 0.7rem;
}

.blue-badge {
    background-color: #569fec;
    color: #fff;
    position: absolute;
    padding: 0.7rem 1.1rem;
    top: 0;
    left: 0;
    border-top-left-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
    text-align: center;
}

.highlight {
    border-color: #007bff !important;
    outline: none !important;
    background-color: #eef5fd !important;
    color: #569fec !important;
}

.card-title {
    font-family: "Georgian";
    font-size: 0.9rem;
    margin: 0.6rem 4rem;
}

.card-body {
    padding: 0.8rem 1.25rem;
}

.card-body input[type="checkbox"] {
    margin-left: 1.5rem;
}

.card-body span {
    color: #787d88;
}

.order-select {
    width: 95%;
    color: #787d88;
}

.order-input {
    width: 100%;
    display: block;
    border-radius: 0.3rem;
    -webkit-border-radius: 0.3rem;
    -moz-border-radius: 0.3rem;
    -ms-border-radius: 0.3rem;
    padding: 0.2rem 0.5rem;
    outline: none;
    border: 1px solid #ccc;
    min-width: 17rem;
    margin: 3px 0 10px 0;
}

.order-input-vertification {
    min-width: 12rem;
    width: 12rem;
    display: inline-block;
}

.btn-vertification {
    display: inline-block;
    padding: 3px;
    background: #569fec;
    border-color: #569fec;
    border-radius: 5px;
    color: #fff;
}

.btn-vertification:hover {
    opacity: 0.7;
}

.order-card-line {
    border-top: 2px dashed #787878;
    margin: 0.3rem 3.3rem 0 3rem;
    position: relative;
    top: -12px;
}

.list-group-item:nth-child(2) {
    font-weight: bold;
    font-size: 1.2rem;
}

.grouped-btn {
    color: #787d88;
    width: 100%;
    margin: 0.7rem;
    border: 1px solid #eee;
    padding: 1.3rem;
    background-color: #fff;
    border-radius: 1rem;
}

.grouped-btn-small {
    padding: 0.7rem 1.1rem;
    padding-left: 1.6rem;
}

.file-spinner-big {
    position: fixed;
    z-index: 5;
    top: 30%;
    margin-left: 9.3rem;
    margin-right: 9.3rem;
}

.loading-message {
    color: #569fec;
    margin: 0 auto;
    font-size: 0.9rem;
    max-width: 18rem;
    text-align: center;
    margin-top: -3rem;
}

.icon {
    position: absolute;
    top: 1.4rem;
    left: 2.7rem;
    font-size: 0.9rem;
}

.fa-icon {
    font-size: 1rem;
}

.info-card {
    position: fixed;
    top: 12.8rem;
}

.info-card .badge-pill {
    font-size: 0.8rem;
}

.info-card-summary {
    position: static;
    margin: 1rem auto;
}

.info-card-header {
    background-color: #569fec;
    font-weight: 400;
    color: #fff;
    text-align: center;
    font-size: 1.3rem;
    letter-spacing: 1px;
}

.info-card-list input {
    font-weight: bold;
    position: absolute;
    right: 8px;
}

.info-card-items {
    visibility: hidden;
}

.info-card-list span {
    position: absolute;
    right: 8px;
}

.order-price {
    color: #007bff;
    font-weight: bold;
    font-size: 1.2rem;
}

.info-card-list input:not([type="checkbox"]) {
    top: 1.2rem;
}

.info-card-list input {
    bottom: 1.8rem;
}

.terms-li-item {
    padding-top: 1rem;
}

.card-information {
    position: absolute;
    top: -2rem;
    right: 1rem;
    background-color: #f1f3f5;
}

.card-information .info-badge {
    padding: 0.3rem;
    font: inherit;
}

.info-card-footer {
    background-color: #569fec;
    color: #fff;
    text-align: center;
}

.info-card-footer a {
    color: #fff;
    width: 100%;
    display: block;
}

.info-card-footer a:hover {
    text-decoration: none;
}

.not-found {
    display: flex;
    justify-content: center;
    padding: 0 0 17rem 0;
}

.not-found hr {
    border: 1px solid #fff;
}

.not-found .heading-alert {
    font-size: 3rem;
}

.ads-heading {
    padding: 1rem;
}

.ads-table {
    text-align: center;
    padding: 1rem;
}

.ads-table tr:nth-child(2n) {
    background-color: rgba(184, 222, 90, 0.1);
}

.ads-table tr:nth-child(2n + 1) {
    background-color: rgba(87, 159, 236, 0.1);
}

.ads-table th {
    background-color: rgb(87, 159, 236);
    color: #ffffff;
}

.ads-table th:nth-child(2) {
    min-width: 10rem;
}

.table-responsive::-webkit-scrollbar {
    -webkit-appearance: none;
}

.table-responsive::-webkit-scrollbar:vertical {
    width: 6px;
}

.table-responsive::-webkit-scrollbar:horizontal {
    height: 6px;
}

.table-responsive::-webkit-scrollbar-thumb {
    background-color: rgba(237, 106, 103, 0.95);
    border-radius: 10px;
    border: 1px solid #ffffff;
}

.table-responsive::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
}

.ads-info {
    background-color: rgb(87, 159, 236);
    color: #ffffff;
    border-radius: 5px;
    box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.4);
    padding: 2rem;
    float: right;
    margin-right: 1rem;
    margin-top: -1.5rem;
    margin-bottom: 1rem;
}

.ads-info span:first-child {
    padding-right: 0.5rem;
    border-right: 1px solid;
}

.ads-info span:last-child {
    padding-left: 0.5rem;
}

.FAQ-h3 {
    text-align: center;
    padding: 0.5rem 0;
}


.toggler-header {
    background-color: #ffffff;
}

.toggler-header:last-child {
    margin-bottom: 1.5rem;
}

.toggler-header h4 {
    font-size: 1.2rem;
    text-align: center;
    display: block;
}

.toggler-btn {
    padding: 0;
    display: block;
    margin: 0 auto;
    text-decoration: none;
    color: #000000;

}

.toggler-btn:hover, .toggler-btn:focus {
    text-decoration: none;

}

.toggler-body p {
    font-size: 1rem;
    text-align: center;
}

.FAQ:last-child {
    padding-bottom: 1rem;
}

.blog-card-title {
    width: 100%;
    margin: 0;
    font-weight: bold;
    text-align: center;
    font-size: 1.1rem;
}

.blog-card {
    overflow: hidden;
}

.blog-card-link:link {
    text-decoration: none;
}

.blog-card-image {
    transition: all 0.3s;

}

.blog-card-image:hover {
    transform: scale(1.1);
    transition: all 0.3s;

}

.blogPost-container p {
    font-size: 1rem;
    margin: 1rem 0;
}

.blogPost-container > img {
    padding: 1rem 0;
    display: block;
    margin: 0 auto;
}

.blogPost-container img {
    width: 100% !important;
    height: auto !important;
}


@media only screen and (max-width: 991px) {
    header {
        text-align: center;
        height: 8.5rem;
    }

    .table-sm td, .table-sm th {
        font-size: 12px;
    }

    header nav ul {
        justify-content: center;
    }

    .landing-section select {
        width: 80%;
        margin: 0 auto;
    }

    .landing-section .info-para {
        margin: 1rem auto;
        text-align: center;
    }

    .center-btn {
        display: block;
        margin: 1rem auto;
    }

    .about-para {
        text-align: center;
    }

    .info-card {
        position: static;
        margin: 1rem auto;
    }

    .file-spinner-big {
        position: absolute;
        left: 32%;
    }

    /* .FAQ div:hover{
      transform: scale(1.05);

    } */

}

@media only screen and (max-width: 768px) {
    .order-card {
        width: 95%;
    }

    .info-card {
        width: 80%;
    }

    .file-spinner-big {
        left: 27%;
    }

    .file-textarea {
        width: 14rem;
    }

    .file-spinner-small {
        left: 47%;
    }

    .upload-btn {
        padding: 3rem 2.6rem;
    }

    .landing-about-article {
        padding-top: 0;
        margin-top: 1rem;
    }

    .landing-section .about-para {
        line-height: 1.5;
        margin-bottom: 0;
    }

    .not-found .heading-alert {
        font-size: 2.5rem;
    }

    .ads-info {
        margin-top: 0rem;
    }
}

@media only screen and (max-width: 660px) {
    .paste-text-icon {
        top: 17rem;
        left: 10.1rem;
    }

    .upload-btn {
        padding: 3rem 5rem;

    }

    .upload-btn-wrapper, .upload-btn {
        width: 100%;
    }

    .uploaded-file-placeholder {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 0%);
    }

    .file-textarea {
        width: 100%;
        padding: 0;
        margin: 0;
        margin-left: 0;
        margin-top: 1rem;
    }

    .card-body .or {
        padding: 0;
        position: static;
        display: block;
        text-align: center;
    }

    .paste-text-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 90%);
    }

}

@media only screen and (max-width: 600px) {
    .header-menu ul li {
        padding: 0 0.5rem;
    }

    .landing-section .info-para {
        margin: 1rem 0;
    }

    .landing-section .about-para {
        line-height: 1.5;
    }

    .landing-header {
        font-size: 1.7rem;
        padding: 0.2rem 0.8rem;
    }

    .landing-info {
        font-size: 1rem;
        width: 100%;
        padding: .5rem;
    }

    .landing-btn {
        width: 90%;
    }


    .card-body .or {
        top: 1.3rem;
        left: -9.5rem;
    }

    .card-body span.or {
        overflow-x: hidden;
    }

    .notary-file-input {
        width: 100%;
        padding-top: 0.5rem;
    }

    .notary-file-input::before {
        content: '\00a0\00a0\00a0\00a0დასკანერებული დოკუმენტის ატვირთვა';
    }

    .notary-file-input::before {
        font-size: 0.8rem;
    }

    .notary-icon {
        position: absolute;
        top: 1.6rem;
        left: 1.2rem;
    }

    .info-card {
        width: 70%;
        padding: 0;
    }

    .file-spinner-big {
        left: 21%;
    }

    .spinner-sm {
        margin: 0 29%;
    }

    .btn-save-text {
        margin-right: 14rem;
    }

    .btn-start-again {
        margin-right: 0;
        padding: 0.8rem 2.3rem;
        margin-bottom: 1rem;
    }

    /* .btn-continue{
      padding: 0.7rem 3rem;
    } */
}

@media only screen and (max-width: 500px) {
    body {
        min-width: 400px;
        /* overflow-x: hidden; */
    }

    header nav,
    header .home {
        font-size: 0.7rem;
    }

    header nav svg {
        height: 1.5rem;
    }

    .header-menu {
        font-size: 83%;
    }

    .not-found .heading-alert {
        font-size: 2rem;
    }

    .grouped-btn svg {
        width: 1rem;
    }

    .grouped-btn .fa-icon {
        width: 0.8rem;
    }

    .file-spinner-big {
        left: 15%;
    }

    .spinner-sm {
        margin: 0 37%;
    }

    .btn-save-text {
        float: none;
        margin: 1rem 0rem;
    }

    .info-card-summary {
        margin: 1rem;
    }

    #med-icon, #notice-icon, #ledger-icon, #agreement-icon, #trust-icon, #ledger-icon {
        display: none;
    }

}

@media only screen and (max-width: 452px) {
    .grouped-btn svg {
        width: 1.3rem;
    }

    .grouped-btn .fa-icon {
        width: 1rem;
    }

    .file-spinner-big {
        left: 11%;
    }

    .notary-icon {
        width: 1rem;
        left: 0.5rem;
    }
}

@media only screen and (max-width: 444px) {

    #med-icon, #notice-icon, #ledger-icon, #agreement-icon, #trust-icon, #ledger-icon {
        display: block;
        left: 2.9rem;
        width: 1.3rem;
    }

    .notary-file-input::before {
        font-size: .75rem;
    }

}

@media only screen and (max-width: 434px) {
    .file-spinner-big {
        left: 9%;
    }

    .spinner-sm {
        margin: 0 45%;
    }

}
